.modal-backdrop {
  background: #00000060 0% 0% no-repeat padding-box !important;
  z-index: 999 !important;
}

.modal {
  z-index: 999 !important;
}

.auth-popup .modal-dialog {
  max-width: 370px;
  margin-right: auto;
  margin-left: auto;
}

.auth-popup .modal-body {
  padding: 35px 20px;
}

.auth-popup .modal-content {
  box-shadow: 0px 0px 15px #00000040;
  border-radius: 10px;
}

.auth-popup .modal-top {
  margin-bottom: 40px;
}

.auth-popup h4 {
  font-size: 32px;
  color: #000000;
  float: left;
  width: 100%;
  margin: 0 0 32px;
  text-align: center;
  padding: 0px;
  font-weight: 600;
  font-family: var(--kg-primary-font);
}

.auth-popup .download-header {
  margin-bottom: 30px;
}

.auth-popup .no-margin {
  margin: 0px;
}

.check-email-popup .modal-dialog {
  max-width: 650px;
  margin-right: auto;
  margin-left: auto;
}

.check-email-popup .modal-body {
  padding: 50px 30px;
  text-align: center;
}

.check-email-popup .modal-content {
  box-shadow: 0px 0px 15px #00000040;
  border-radius: 10px;
}

.check-email-popup .modal-top {
  margin-bottom: 40px;
}

.check-email-popup .close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.check-email-popup .close-btn svg {
  stroke-width: 8rem;
  stroke: #6b7280;
  width: 32px;
  height: 32px;
  padding: 8px;
}

.check-email-popup h4 {
  font-size: 32px;
  font-weight: 600;
}

.check-email-popup p {
  font-size: 20px;
  margin-top: 16px;
}

.check-email-popup .resend-btn {
  margin-top: 40px;
}

.check-email-popup .resend-btn button {
  padding: 8px 35px;
  margin: auto;
}

.check-email-popup .resend-btn button::before {
  border-radius: 12px;
}

.check-email-popup .resend-btn span {
  font-weight: 600;
}

/* Popup Css */

.loading-dot-container {
  display: inline-block;
}

.loading-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 2px;
  background-color: black;
  border-radius: 50%;
  opacity: 0;
  animation: blink 1.4s infinite both;
  background: var(--linear-gradient);
  margin: 0 5px;
}

.loading-dot:nth-child(1) {
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.googlebtn,
.chabadbtn {
  border: 1px solid #c4c4c4;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.6px;
  color: #696969 !important;
  text-transform: uppercase;
  float: left;
  width: 100%;
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-family: var(--kg-primary-font);
  padding: 15px 10px;
  border-radius: 5px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.googlebtn img {
  max-width: 20px !important;
}

.googlebtn > span {
  margin-right: 15px !important;
}

.chabadbtn {
  margin: 10px 0 0;
}

.googlebtn {
  padding: 12px 10px;
}

.divider {
  float: left;
  width: 100%;
  position: relative;
}

.divider hr {
  margin: 1.8rem 0;
}

.divider label {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 12px;
  line-height: 17px;
  height: 19px;
  background: #fff;
  display: table;
  padding: 0 10px;
  font-family: var(--kg-primary-font);
  color: #000;
}

.auth-popup form {
  float: left;
  width: 100%;
}

.auth-popup form label {
  float: left;
  width: 100%;
  font-family: var(--kg-primary-font);
  color: #000;
  font-size: 12px;
  margin: 0 0 5px;
  line-height: 18px;
}

.auth-popup form .form-control {
  width: 100%;
  padding: 10px 15px;
  font-size: 14px;
  font-family: var(--kg-primary-font);
  color: #000;
  margin: 0;
  line-height: 20px;
  border-color: #c4c4c4 !important;
  box-shadow: none !important;
  border-radius: 5px;
}

.auth-popup form .btn {
  width: 100%;
  padding: 16px;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
  margin: 0;
  line-height: 12px;
  background: var(--linear-gradient);
  border-radius: 5px;
  box-shadow: none !important;
  border: 0;
}

.auth-popup form .btn.pay-btn {
  font-size: 15px;
}

.auth-popup .forgot-password {
  text-align: center !important;
  font-size: 12px;
  padding-top: 5px;
  color: #000;
  font-family: var(--kg-primary-font);
  display: table;
  margin: auto;
}

.auth-popup .forgot-password a {
  color: #d938b9;
  text-decoration: none;
}

.auth-popup .forgot-password button {
  background-color: transparent !important;
  border: 0px;
  padding: 0px;
  text-transform: capitalize;
  width: auto;
  line-height: 18px;
  float: right;
  margin: 0 0 0 5px;
}

.auth-popup .forgot-password.error {
  color: red;
}
.auth-popup .forgot-password button.bp5-button {
  box-shadow: none !important;
  min-height: inherit;
  font-size: inherit;
  min-width: inherit;
  border-radius: 0px;
}
.chabadButton {
  display: flex;
  border: 1px solid #c4c4c4;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.6px;
  color: #696969 !important;
  text-transform: uppercase;
  float: left;
  width: 100%;
  margin: 0;
  text-align: center;
  font-weight: 600;
  font-family: var(--kg-primary-font);
  padding: 15px 10px;
  border-radius: 5px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 12px 10px;
}
